export default [
    {
        "dish": "Dosa",
        "description": "Dosa is a popular South Indian pancake made from a fermented batter of rice and urad dal (black gram). It's thin, crispy, and versatile, often served with a variety of fillings or side dishes, making it a beloved and savory treat."
    },
    {
        "dish": "Parata",
        "description": "Parata is a traditional Indian flatbread, known for its flaky and layered texture.It's typically made from a simple mixture of flour, ghee, and water, and can be enjoyed with various accompaniments such as chutneys or curries."
    },
    {
        "dish": "Meat Platter",
        "description": "A meat platter is a dish featuring an assortment of cooked and often sliced meats, such as ham, salami, roast beef, and turkey, served cold or at room temperature. It's commonly served as an appetizer or part of a charcuterie board, offering a variety of flavors and textures."
    },
    {
        "dish": "Coffee",
        "description": "Coffee is a popular beverage made from roasted coffee beans, known for its caffeine content and wide range of flavors and brewing methods. It is enjoyed worldwide for its stimulating effects and diverse taste profiles, from bold and bitter to sweet and aromatic."
    },
    {
        "dish": "Frappuccino",
        "description": "A Frappuccino is a popular coffee beverage known for its creamy blend of coffee or crème, ice, and whipped cream on top. It's a delicious and refreshing choice for those seeking a cool and indulgent coffee treat."
    },
    {
        "dish": "Appetizer",
        "description": "Appetizers are small portions of food served before a meal to stimulate the appetite."
    },
    {
        "dish": "Salad",
        "description": "Salads are dishes made from a mixture of vegetables, greens, and often accompanied by toppings, dressings, and sometimes proteins."
    },
    {
        "dish": "Soup",
        "description": "Soup is a liquid dish made by boiling ingredients like vegetables, meat, or fish in a broth or water."
    },
    {
        "dish": "Sandwich",
        "description": "A sandwich is a food item consisting of ingredients such as meat, cheese, vegetables, or spreads, placed between slices of bread."
    },
    {
        "dish": "Burger",
        "description": "A burger is a sandwich made with a ground meat patty, typically served in a bun and often accompanied by various toppings."
    },
    {
        "dish": "Pizza",
        "description": "Pizza is a round, flatbread topped with tomato sauce, cheese, and various toppings, then baked until the crust is crispy."
    },
    {
        "dish": "Pasta",
        "description": "Pasta dishes consist of various shapes of pasta, often served with sauces and toppings like meat, vegetables, or cheese."
    },
    {
        "dish": "Rice Dish",
        "description": "Rice dishes are prepared with rice as the main ingredient, often accompanied by vegetables, meat, or seafood."
    },
    {
        "dish": "Noodle Dish",
        "description": "Noodle dishes feature various types of noodles, often stir-fried or in soup, and accompanied by vegetables, meat, or seafood."
    },
    {
        "dish": "Stir-Fry",
        "description": "Stir-fry dishes involve quickly frying ingredients in a hot pan, often including vegetables, meat, and sauce."
    },
    {
        "dish": "Curry",
        "description": "Curry dishes include a variety of spiced dishes with a sauce, often served with rice or bread."
    },
    {
        "dish": "Stew",
        "description": "Stews are slow-cooked dishes with ingredients like meat, vegetables, and potatoes, often in a flavorful broth or sauce."
    },
    {
        "dish": "Grilled Dish",
        "description": "Grilled dishes involve cooking food on a grill, imparting a charred and smoky flavor to the ingredients."
    },
    {
        "dish": "Roast",
        "description": "Roasts are large cuts of meat or poultry cooked by roasting, often seasoned and served as a main course."
    },
    {
        "dish": "Fried Dish",
        "description": "Fried dishes involve cooking food by submerging it in hot oil, resulting in a crispy exterior."
    },
    {
        "dish": "Baked Dish",
        "description": "Baked dishes are prepared by cooking ingredients in an oven, often covered with cheese, sauce, or breadcrumbs."
    },
    {
        "dish": "Casserole",
        "description": "Casseroles are one-dish meals made by baking a mixture of ingredients like meat, vegetables, and starches."
    },
    {
        "dish": "Tacos",
        "description": "Tacos are folded or rolled tortillas filled with various ingredients, often including meat, vegetables, cheese, and salsa."
    },
    {
        "dish": "Burritos",
        "description": "Burritos are large tortillas wrapped around fillings like rice, beans, meat, and vegetables."
    },
    {
        "dish": "Enchiladas",
        "description": "Enchiladas are tortillas rolled around fillings, covered with sauce, and baked until heated."
    },
    {
        "dish": "Sushi",
        "description": "Sushi is a Japanese dish made with vinegared rice, often combined with raw fish, vegetables, and seaweed."
    },
    {
        "dish": "Sashimi",
        "description": "Sashimi consists of thinly sliced raw fish or seafood, often served with soy sauce and wasabi."
    },
    {
        "dish": "Tempura",
        "description": "Tempura is a Japanese dish made by deep-frying battered seafood, vegetables, or other ingredients."
    },
    {
        "dish": "Dim Sum",
        "description": "Dim sum are bite-sized Chinese dishes often served in small steamer baskets or on small plates."
    },
    {
        "dish": "Dumplings",
        "description": "Dumplings are dough pockets filled with meat, vegetables, or other fillings, often steamed or fried."
    },
    {
        "dish": "Pancakes",
        "description": "Pancakes are flat, round cakes made from a batter of flour, eggs, and milk, often served with toppings."
    },
    {
        "dish": "Omelette",
        "description": "An omelette is a dish made by beating eggs and cooking them in a pan, often with various fillings."
    },
    {
        "dish": "Crepes",
        "description": "Crepes are thin, delicate pancakes often filled with sweet or savory ingredients."
    },
    {
        "dish": "Sausages",
        "description": "Sausages are seasoned ground meat encased in a casing, often cooked by grilling, frying, or boiling."
    },
    {
        "dish": "Meatballs",
        "description": "Meatballs are small balls of ground meat, often served with sauce and pasta or as appetizers."
    },
    {
        "dish": "Kebabs",
        "description": "Kebabs are skewers of grilled or roasted meat, often accompanied by vegetables."
    },
    {
        "dish": "Sausage Roll",
        "description": "A sausage roll is a pastry filled with seasoned sausage meat, often baked until golden."
    },
    {
        "dish": "Pie",
        "description": "A pie is a baked dish with a pastry crust and sweet or savory filling, often enclosed in the crust."
    },
    {
        "dish": "Quiche",
        "description": "A quiche is a savory pie with a pastry crust and a filling of eggs, cream, cheese, and various ingredients."
    },
    {
        "dish": "Sushi Roll",
        "description": "A sushi roll is a cylindrical roll of vinegared rice, often wrapped in seaweed and filled with fish, vegetables, or other ingredients."
    },
    {
        "dish": "Ramen",
        "description": "Ramen is a Japanese dish consisting of Chinese-style wheat noodles served in a meat or fish-based broth, often with toppings."
    },
    {
        "dish": "Pho",
        "description": "Pho is a Vietnamese noodle soup made with broth, rice noodles, herbs, and meat, often enjoyed for its rich flavor."
    },
    {
        "dish": "Bibimbap",
        "description": "Bibimbap is a Korean dish of rice topped with various ingredients like vegetables, meat, egg, and spicy sauce."
    },
    {
        "dish": "Peking Duck",
        "description": "Peking Duck is a famous Chinese dish made from roasted duck served with thin pancakes, scallions, and hoisin sauce."
    },
    {
        "dish": "Tandoori",
        "description": "Tandoori dishes are cooked in a clay oven called a tandoor, resulting in a smoky flavor and distinctive charred appearance."
    },
    {
        "dish": "Shawarma",
        "description": "Shawarma is a Middle Eastern dish made by stacking marinated meat on a vertical rotisserie, often served in pita bread."
    },
    {
        "dish": "Falafel",
        "description": "Falafel is a deep-fried ball or patty made from ground chickpeas or fava beans, often served in pita bread or as a snack."
    },
    {
        "dish": "Samosa",
        "description": "A samosa is a fried or baked pastry filled with spiced potatoes, peas, meat, or other fillings, often enjoyed as a snack."
    },
    {
        "dish": "Fish and Chips",
        "description": "Fish and chips is a British dish consisting of battered and deep-fried fish served with fried potato chips."
    },
    {
        "dish": "Biryani",
        "description": "Biryani is a flavorful rice dish made with spiced rice, meat, vegetables, and fragrant spices, often layered and cooked together."
    },
    {
        "dish": "Risotto",
        "description": "Risotto is an Italian dish made with Arborio rice cooked in a broth until creamy, often flavored with wine and various ingredients."
    },
    {
        "dish": "Guacamole",
        "description": "Guacamole is a Mexican dip made from mashed avocados, often mixed with lime juice, onions, tomatoes, and spices."
    },
    {
        "dish": "Hummus",
        "description": "Hummus is a Middle Eastern dip made from blended chickpeas, tahini, lemon juice, and garlic, often served with pita bread."
    },
    {
        "dish": "Salsa",
        "description": "Salsa is a sauce made from chopped tomatoes, onions, peppers, and spices, often served with tortilla chips or as a topping."
    },
    {
        "dish": "Ceviche",
        "description": "Ceviche is a dish made from fresh raw fish or seafood marinated in citrus juices, often accompanied by onions, peppers, and herbs."
    }
]
