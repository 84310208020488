export default [
    {
        "flavor": "Sweet",
        "description": "Sweet flavors are characterized by a sugary taste that is often associated with desserts and fruits."
    },
    {
        "flavor": "Savory (Umami)",
        "description": "Savory flavors, also known as umami, are rich and satisfying tastes often found in foods like meats, mushrooms, and aged cheeses."
    },
    {
        "flavor": "Sour",
        "description": "Sour flavors are tangy and acidic tastes that come from ingredients like citrus fruits, vinegar, and fermented foods."
    },
    {
        "flavor": "Bitter",
        "description": "Bitter flavors are often found in foods like dark chocolate, coffee, and certain leafy greens. They can be strong and slightly astringent."
    },
    {
        "flavor": "Salty",
        "description": "Salty flavors are caused by the presence of salt and are often used to enhance other flavors in dishes."
    },
    {
        "flavor": "Spicy (Heat)",
        "description": "Spicy flavors are characterized by a sensation of heat and can come from ingredients like chili peppers, hot sauces, and spices."
    },
    {
        "flavor": "Herbaceous",
        "description": "Herbaceous flavors are fresh and green, often coming from herbs like basil, mint, parsley, and cilantro."
    },
    {
        "flavor": "Citrusy",
        "description": "Citrusy flavors are bright and tangy, reminiscent of citrus fruits like lemons, oranges, and grapefruits."
    },
    {
        "flavor": "Earthy",
        "description": "Earthy flavors are grounded and rich, often coming from ingredients like root vegetables, mushrooms, and nuts."
    },
    {
        "flavor": "Fruity",
        "description": "Fruity flavors come from a variety of fruits and can range from sweet to tart, adding a refreshing and juicy element to dishes."
    },
    {
        "flavor": "Smoky",
        "description": "Smoky flavors are obtained through smoking or grilling ingredients and can add depth and complexity to foods."
    },
    {
        "flavor": "Nutty",
        "description": "Nutty flavors are rich and often come from nuts like almonds, walnuts, and pecans, adding a crunchy and flavorful element."
    },
    {
        "flavor": "Mild",
        "description": "Mild flavors are gentle and not overpowering, allowing other ingredients to shine while providing a subtle taste."
    },
    {
        "flavor": "Tangy",
        "description": "Tangy flavors have a sharp and zesty taste, often attributed to ingredients like vinegar, yogurt, and citrus."
    },
    {
        "flavor": "Creamy",
        "description": "Creamy flavors are rich and smooth, often associated with ingredients like dairy products, sauces, and desserts."
    },
    {
        "flavor": "Aromatic",
        "description": "Aromatic flavors are fragrant and often come from herbs, spices, and ingredients that release enticing scents when cooked."
    },
    {
        "flavor": "Peppery",
        "description": "Peppery flavors have a distinct spiciness, often associated with black pepper and other pungent spices."
    },
    {
        "flavor": "Zesty",
        "description": "Zesty flavors are lively and invigorating, often featuring a combination of bright citrus and herbs."
    },
    {
        "flavor": "Robust",
        "description": "Robust flavors are hearty and full-bodied, providing a satisfying and intense taste experience."
    },
    {
        "flavor": "Cooling",
        "description": "Cooling flavors offer a refreshing sensation, often attributed to ingredients like mint and cucumber."
    },
    {
        "flavor": "Peaty",
        "description": "Peaty flavors are smoky and earthy, often found in certain types of whiskey and foods cooked over open flames."
    }
]
