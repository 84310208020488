import ExcelJS from "exceljs"
import { capitalCase, sentenceCase } from "change-case";
import numeral from "numeral";
import currency from "currency.js";
import { filter, find, rest, uniq } from "underscore";
// import libPhoneNumber from "google-libphonenumber";

import DietaryPreferences from "./metadata/DietaryPreferences.js";
import Cuisines from "./metadata/Cuisines.js";
import DishTypes from "./metadata/Dishes.js";
import FlavorProfiles from "./metadata/Flavors.js";
import Tags from "./metadata/Tags.js";

// const phoneUtil = libPhoneNumber.PhoneNumberUtil.getInstance();
// const PhoneNumberFormat = libPhoneNumber.PhoneNumberFormat;

const RESTAURANT_COLUMN_NAMES = {
    NAME: "A",
    SUB_NAME: "B",
    TAGS: "C",
    STREET_ADD_1: "D",
    STREET_ADD_2: "E",
    CITY: "F",
    STATE_PROV_REGION: "G",
    POSTAL_ZIP_CODE: "H",
    LAT: "I",
    LNG: "J",
    COUNTRY: "K",
    TELEPHONES: "L",

}

const MENU_COLUMN_NAMES = {
    SECTION: "A",
    SECTION_DESCRIPTION: "B",
    ITEM_NUMBER: "C",
    ITEM_NAME: "D",
    SLUG_NAME: "E",
    DISH_TYPE: "F",
    CUISINE: "G",
    FLAVOR_PROFILE: "H",
    DIETARY_PREFERENCE: "I",
    CONTAINS_ALCOHOL: "J",
    SPECIAL_NOTE: "K",
    INGREDIENTS: "L",
    CONTAINS: "M",
    DESCRIPTION: "N",
    PRICE_DESCRIPTION: "O",
    PRICE: "P",
    ADDON_NAME: "Q",
    ADDON_PRICE: "R",
}

var LKR = value => currency(value, { symbol: "Rs.", });

const colNum = char => (Number(char.toUpperCase().charCodeAt(0)) - 64)

const checkDietaryPreferences = (pref) => {
    const found = find(DietaryPreferences, (d) => d.preference === pref);
    return found;
}

const checkFlavorProfile = (flavor) => {
    const found = find(FlavorProfiles, (d) => d.flavor === flavor);
    return found;
}

const checkCuisine = (cuisine) => {
    const found = find(Cuisines, (d) => d.cuisine === cuisine);
    return found;
}

const checkDishType = (dish) => {
    const found = find(DishTypes, (d) => d.dish === dish);
    return found;
}


function stringToBoolean(str) {

    const upperCaseStr = str.toUpperCase();

    if (
        upperCaseStr !== "TRUE" &&
        upperCaseStr !== "FALSE" &&
        upperCaseStr !== "UNDEFINED"
    ) {
        throw new Error(`invalid string for boolean column "${upperCaseStr}"`)
    }

    return upperCaseStr === "TRUE";
}

function rToArr(row) {
    return Array.from(row.values.splice(1));
}

/**
 * My cool function.
 *
 * @param {Object} argObject - An object.
 * @param {ExcelJS.Worksheet} argObject.sheet - Sheet
 * @param {Number} argObject.column - Column
 */
async function getColumnRanges({
    sheet,
    column,
    min,
    max
}) {

    const ranges = [];

    var currentCell;
    var masterCell;

    for (var x = min; x <= max + 1; x++) {
        currentCell = sheet.getCell(x, column);

        if (!masterCell) {
            masterCell = currentCell;
            continue;
        }

        if (!currentCell.isMergedTo(masterCell)) {
            ranges.push({
                value: masterCell.value,
                start: masterCell.row,
                end: currentCell.row - 1, //since its not merged at current cell. it ended at the previous cell
                column,
            });
            masterCell = currentCell;
            currentCell = undefined;
        }
    }
    return ranges.filter(x => x.value !== undefined && x.value !== null);
}

async function getItemPrices({
    sheet,
    start,
    end
}) {
    const prices = await getColumnRanges({
        sheet,
        min: start,
        max: end,
        column: colNum(MENU_COLUMN_NAMES.PRICE),
    })
    for (const p of prices) {
        const descCell = sheet.getCell(p.start, colNum(MENU_COLUMN_NAMES.PRICE_DESCRIPTION));
        p.description = descCell.value;
        // p.value = p.value.replace("Rs.", "Rs")
    }
    if (prices.length > 1) {
        for (const p of prices) {
            if (Number.isNaN(numeral(p.value)) || (!p.description)) {
                throw new Error(`error on item price, row:${p.start} price:${p.value} desc:${p.description}`)
            }
        }
    }
    return prices.map(p => {
        p.value = numeral(p.value).value();
        return p;
    })
}

async function getSigleValues({
    sheet,
    columnLetter,
    start,
    end
}) {

    var vals = await getColumnRanges({
        sheet,
        min: start,
        max: end,
        column: colNum(columnLetter),
    });

    const _vals = uniq(vals.map(x => x.value));

    if (_vals.length > 1) {
        throw new Error(`error on single item vals, col ${columnLetter} start:${start} end:${end} , [${vals.map(x => x.value).join(",")}]`)
    }
    return _vals[0];
}


/**
 * My cool function.
 *
 * @param {Object} argObject - An object.
 * @param {ExcelJS.Worksheet} argObject.sheet - Sheet
 */
async function getMenuJSON({
    sheet
}) {

    const sectionsArr = [];
    const sections = await getColumnRanges({
        sheet,
        column: colNum(MENU_COLUMN_NAMES.SECTION),
        min: 3,
        max: 240
    });

    for (const sec of sections) {
        const itemsArr = [];
        const sectionObj = {
            name: capitalCase(sec.value),
            items: itemsArr
        }

        //section desc
        const sectionDesc = await getSigleValues({
            sheet,
            start: sec.start,
            end: sec.end,
            columnLetter: MENU_COLUMN_NAMES.SECTION_DESCRIPTION
        });

        sectionObj.description = sectionDesc
        sectionsArr.push(sectionObj);
        const items = await getColumnRanges({
            sheet,
            min: sec.start,
            max: sec.end,
            column: colNum(MENU_COLUMN_NAMES.ITEM_NAME)
        });

        for (const item of items) {

            const addonsArr = [];
            const itemObj = {
                name: item.value,
                addons: addonsArr
            }
            itemsArr.push(itemObj);

            //Item Prices
            const prices = await getItemPrices({
                sheet,
                start: item.start,
                end: item.end
            })

            itemObj.prices = prices.map(p => {
                p.description = capitalCase(p.description || "Default");
                return {
                    label: p.description,
                    price: numeral(p.value).value()
                }
            })

            //Item Description
            const itemDesc = await getSigleValues({
                sheet,
                start: item.start,
                end: item.end,
                columnLetter: MENU_COLUMN_NAMES.DESCRIPTION
            });
            itemObj.description = capitalCase(itemDesc || "")

            ///Contains = includes. this needs to be ab array like below.
            //Contains Note (containsNote = includes)
            const includesStr = await getSigleValues({
                sheet,
                start: item.start,
                end: item.end,
                columnLetter: MENU_COLUMN_NAMES.CONTAINS
            });
            var includesArr = [];
            if (includesStr) {
                includesArr = includesStr.split("\n").map(x => {
                    const splitArr = x.split(/\s*[xX]\s*/);
                    if (splitArr.length !== 2) {
                        throw new Error("error on include " + itemObj.name + " " + x)
                    }
                    const [quantityStr, nameStr] = splitArr;
                    const num = Number(quantityStr);
                    if (Number.isNaN(num)) {
                        throw new Error("error on include NAN quantity " + itemObj.name + " " + x)
                    }

                    if (num % 1 !== 0) {
                        throw new Error("error on include non whole quantity " + itemObj.name + " " + x)
                    }
                    return {
                        name: sentenceCase(nameStr),
                        quantity: num
                    }
                });
            }
            itemObj.includes = includesArr

            //Special Note
            const itemSpecialNote = await getSigleValues({
                sheet,
                start: item.start,
                end: item.end,
                columnLetter: MENU_COLUMN_NAMES.SPECIAL_NOTE
            });
            itemObj.specialNote = capitalCase(itemSpecialNote || "")

            //Contains Alcohol
            const itemContainsAlcohol = await getSigleValues({
                sheet,
                start: item.start,
                end: item.end,
                columnLetter: MENU_COLUMN_NAMES.CONTAINS_ALCOHOL
            });

            itemObj.containsAlcohol = stringToBoolean(`${itemContainsAlcohol}`)

            //Flavor Profile, currently only getting single value, but Category Service is defined to handle multiple favaor profiles
            const itemFlavorProfile = await getSigleValues({
                sheet,
                start: item.start,
                end: item.end,
                columnLetter: MENU_COLUMN_NAMES.FLAVOR_PROFILE
            });

            if (itemFlavorProfile) {
                if (!checkFlavorProfile(itemFlavorProfile)) {
                    throw new Error(`invalid flavor profile row:${item.start} (${itemFlavorProfile})`)
                }

                itemObj.flavorProfiles = [itemFlavorProfile]
            }

            //Dietary Preference, currently only getting single value, but Category Service is defined to handle multiple Dietary preferences
            const itemDietaryPreference = await getSigleValues({
                sheet,
                start: item.start,
                end: item.end,
                columnLetter: MENU_COLUMN_NAMES.DIETARY_PREFERENCE
            });

            if (itemDietaryPreference) {
                if (!checkDietaryPreferences(itemDietaryPreference)) {
                    throw new Error(`invalid dietary preference row:${item.start} (${itemDietaryPreference})`)
                }

                itemObj.dietaryPreferences = [itemDietaryPreference]
            }

            //Cuisine
            const itemCuisine = await getSigleValues({
                sheet,
                start: item.start,
                end: item.end,
                columnLetter: MENU_COLUMN_NAMES.CUISINE
            });

            if (itemCuisine) {
                if (!checkCuisine(itemCuisine)) {
                    throw new Error(`invalid cuisine row:${item.start} (${itemCuisine})`)
                }

                itemObj.cuisine = itemCuisine
            }

            //Dish Type
            const itemDishType = await getSigleValues({
                sheet,
                start: item.start,
                end: item.end,
                columnLetter: MENU_COLUMN_NAMES.DISH_TYPE
            });

            if (itemDishType) {
                if (!checkDishType(itemDishType)) {
                    throw new Error(`invalid dish type row:${item.start} (${itemDishType})`)
                }

                itemObj.dishType = itemDishType
            }

            //Contains Alcohol
            const itemImageSlug = await getSigleValues({
                sheet,
                start: item.start,
                end: item.end,
                columnLetter: MENU_COLUMN_NAMES.SLUG_NAME
            });

            if (itemImageSlug) {
                itemObj.imageSlug = itemImageSlug
            }

            //item number
            const itemNumber = await getSigleValues({
                sheet,
                start: item.start,
                end: item.end,
                columnLetter: MENU_COLUMN_NAMES.ITEM_NUMBER
            });

            if (itemNumber) {
                itemObj.itemNumber = itemNumber
            }

            //-----Addons
            const addons = await getColumnRanges({
                sheet,
                min: item.start,
                max: item.end,
                column: colNum(MENU_COLUMN_NAMES.ADDON_NAME)
            });

            for (const a of addons) {

                if (a.start !== a.end) {
                    throw new Error("Merge detected in Addons");
                }

                const priceCell = sheet.getCell(a.start, colNum(MENU_COLUMN_NAMES.ADDON_PRICE));
                if (!priceCell) {
                    throw new Error("Error with addon price", priceCell);
                }
                const addonPrice = numeral(priceCell.value);

                if (Number.isNaN(addonPrice.value())) {
                    throw new Error("Error with addon price", priceCell);
                }

                addonsArr.push({
                    name: a.value,
                    price: addonPrice.value()
                })
            }
        }
    }

    return sectionsArr;
}

const exp = async function ({ menuSheet, restInfoSheet }) {
    const data = await getMenuJSON({
        sheet: menuSheet
    });
    const restInfoRow = restInfoSheet.getRow(2)

    var telephones = [];
    var _telephones;
    const telephoneStr = restInfoRow.getCell(RESTAURANT_COLUMN_NAMES.TELEPHONES).value.toString();

    if (telephoneStr) {
        _telephones = telephoneStr.split(",");
    }

    if (_telephones) {
        for (var x = 0; x < _telephones.length; x++) {
            const tp = _telephones[x];

            //since this runs on browser its commented, but will be used when using NODE.js
            // const number = phoneUtil.parseAndKeepRawInput(tp, 'LK');
            // const isValid = phoneUtil.isValidNumberForRegion(number, 'LK');
            // if (!isValid) {
            //     throw new Error("invalid telephone " + tp)
            // }
            // telephones.push(phoneUtil.format(number, PhoneNumberFormat.NATIONAL))
            telephones.push(tp);
        }
    }
    var tags = [];
    var _tags;
    const tagStr = restInfoRow.getCell(RESTAURANT_COLUMN_NAMES.TAGS).value.toString();

    if (tagStr) {
        _tags = tagStr.split(",");
    }

    if (_tags) {
        for (var x = 0; x < _tags.length; x++) {
            const tag = _tags[x].toLowerCase().trim();
            if (find(Tags, x => x === tag) === undefined) {
                throw new Error("invalid tag " + tag)
            };

            tags.push(tag);
        }
    }

    const restData = {
        name: restInfoRow.getCell(RESTAURANT_COLUMN_NAMES.NAME).value,
        subName: restInfoRow.getCell(RESTAURANT_COLUMN_NAMES.SUB_NAME).value,
        streetAddress1: `${restInfoRow.getCell(RESTAURANT_COLUMN_NAMES.STREET_ADD_1).value}`,
        streetAddress2: `${restInfoRow.getCell(RESTAURANT_COLUMN_NAMES.STREET_ADD_2).value}`,
        city: restInfoRow.getCell(RESTAURANT_COLUMN_NAMES.CITY).value,
        state_province_region: restInfoRow.getCell(RESTAURANT_COLUMN_NAMES.STATE_PROV_REGION).value,
        postalCode_zipCode: Number.parseInt(restInfoRow.getCell(RESTAURANT_COLUMN_NAMES.POSTAL_ZIP_CODE).value),
        country: 144,
        tags,
        telephones,
        menu: data
    }

    return restData;

}

export default exp;