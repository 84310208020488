export default [
    {
        "preference": "Vegetarian",
        "description": "Vegetarians avoid meat and seafood, but may still consume dairy products, eggs, and plant-based foods."
    },
    {
        "preference": "Vegan",
        "description": "Vegans avoid all animal products, including meat, dairy, eggs, and any ingredients derived from animals."
    },
    {
        "preference": "Pescatarian",
        "description": "Pescatarians eat seafood and fish, but avoid other types of meat. They often include plant-based foods in their diet."
    },
    {
        "preference": "Flexitarian",
        "description": "Flexitarians primarily follow a vegetarian diet but occasionally eat meat or fish in moderation."
    },
    {
        "preference": "Paleo",
        "description": "The paleo diet emphasizes whole foods and excludes processed foods, grains, legumes, and dairy products."
    },
    {
        "preference": "Keto",
        "description": "The keto diet is low-carb and high-fat, aiming to put the body into a state of ketosis for fat-burning."
    },
    {
        "preference": "Gluten-Free",
        "description": "Gluten-free diets exclude gluten, a protein found in wheat, barley, and rye, to accommodate those with gluten sensitivities or celiac disease."
    },
    {
        "preference": "Dairy-Free",
        "description": "Dairy-free diets exclude all dairy products and ingredients containing lactose, suitable for those with lactose intolerance or dairy allergies."
    },
    {
        "preference": "Nut-Free",
        "description": "Nut-free diets avoid all types of nuts to accommodate allergies or sensitivities to nuts."
    },
    {
        "preference": "Low-Sodium",
        "description": "Low-sodium diets limit salt intake and are often recommended for those with high blood pressure or heart conditions."
    },
    {
        "preference": "Halal",
        "description": "Halal diets adhere to Islamic dietary laws and exclude certain foods and ingredients, including pork and non-halal meat."
    },
    {
        "preference": "Kosher",
        "description": "Kosher diets adhere to Jewish dietary laws and have specific rules about which foods are permitted and how they are prepared."
    },
    {
        "preference": "Raw",
        "description": "Raw diets consist of uncooked and minimally processed foods, with a focus on fruits, vegetables, nuts, and seeds."
    },
    {
        "preference": "Organic",
        "description": "Organic diets prioritize foods that are grown without synthetic pesticides, fertilizers, or genetically modified organisms (GMOs)."
    },
    {
        "preference": "Mediterranean",
        "description": "The Mediterranean diet emphasizes whole grains, fruits, vegetables, and healthy fats, inspired by the dietary habits of Mediterranean countries."
    },
    {
        "preference": "Low-Carb",
        "description": "Low-carb diets restrict carbohydrate intake to promote weight loss and control blood sugar levels."
    },
    {
        "preference": "Plant-Based",
        "description": "Plant-based diets focus on foods derived from plants and minimize or exclude animal products."
    },
    {
        "preference": "Low-FODMAP",
        "description": "Low-FODMAP diets are designed to reduce intake of certain fermentable carbohydrates, often recommended for those with irritable bowel syndrome (IBS)."
    },
    {
        "preference": "Anti-Inflammatory",
        "description": "Anti-inflammatory diets emphasize foods that may help reduce inflammation in the body, often rich in fruits, vegetables, and healthy fats."
    }
]
