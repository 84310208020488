//Styles
import './App.css';
import 'react18-json-view/src/style.css'

//Libs
import { useState } from 'react';
import ReactJson from 'react18-json-view'
import { useForm } from "react-hook-form";
import toJSON from './toJSON.js';
import ExcelJS from "exceljs"

const testData = {
  "name": "Taphouse by RnR",
  "subName": "",
  "streetAddress1": "Dutch Hospital - Shopping Precinct",
  "streetAddress2": "Hospital St",
  "city": "Colombo",
  "state_province_region": "Western",
  "postalCode_zipCode": null,
  "country": 144,
  "tags": ["dine-in", "take-away"],
  "telephones": ["0112 878 878"],
  "menu": [
    { "name": "Burgers", "items": [] },
    { "name": "Pol Roti Kottu", "items": [] },
    { "name": "Others", "items": [] },
    { "name": "Rice", "items": [] },
    { "name": "Pasta", "items": [] },
    { "name": "Beer Off The Tap", "items": [] },
    { "name": "Beer Off The Bottle", "items": [] },
    { "name": "Mocktails", "items": [] },
    { "name": "Popular At Taphouse", "items": [] },
    { "name": "Cocktail Pitchers", "items": [] },
    { "name": "Mojito", "items": [] },
    { "name": "Shooters", "items": [] },
    { "name": "Tequila", "items": [] },
    { "name": "Brandy", "items": [] },
    { "name": "Arrack", "items": [] },
    { "name": "Vodka", "items": [] },
    { "name": "Cocktails", "items": [] },
    { "name": "Gin", "items": [] },
    { "name": "Rum", "items": [] },
    { "name": "Liqueur", "items": [] },
    { "name": "Bourbon", "items": [] },
    { "name": "Wine", "items": [] },
    { "name": "Chasers", "items": [] },
    { "name": "Whiskey", "items": [] },
    { "name": "Cigarettes", "items": [] },
    { "name": "Bites", "items": [] }
  ]
};

const RESTAURANT_COLUMN_NAMES = {
  NAME: "A",
  SUB_NAME: "B",
  TAGS: "C",
  STREET_ADD_1: "D",
  STREET_ADD_2: "E",
  CITY: "F",
  STATE_PROV_REGION: "G",
  POSTAL_ZIP_CODE: "H",
  LAT: "I",
  LNG: "J",
  COUNTRY: "K",
  TELEPHONES: "L",

}

const MENU_COLUMN_NAMES = {
  SECTION: "A",
  SECTION_DESCRIPTION: "B",
  ITEM_NUMBER: "C",
  ITEM_NAME: "D",
  SLUG_NAME: "E",
  DISH_TYPE: "F",
  CUISINE: "G",
  FLAVOR_PROFILE: "H",
  DIETARY_PREFERENCE: "I",
  CONTAINS_ALCOHOL: "J",
  SPECIAL_NOTE: "K",
  INGREDIENTS: "L",
  CONTAINS: "M",
  DESCRIPTION: "N",
  PRICE_DESCRIPTION: "O",
  PRICE: "P",
  ADDON_NAME: "Q",
  ADDON_PRICE: "R",
}

function ColTable({ data }) {
  return <table className='table table-xs'>
    <thead>
      <tr>
        <th>Column Name</th>
        <th>Column Letter</th>
      </tr>
    </thead>
    <tbody>
      {Object.entries(data).map(([key, val]) => <tr>
        <td>{key}</td>
        <td>{val}</td>
      </tr>)}
    </tbody>
  </table>
}

function App() {

  const [error, setError] = useState();
  const [json, setJSON] = useState();
  const [isProcessing, setIsProcessing] = useState(false);

  const { register, handleSubmit, watch, formState: { errors }, clearErrors } = useForm();
  const onSubmit = data => {
    if ((data.file[0])) {
      const fileReader = new FileReader();
      fileReader.onerror = () => {
        setIsProcessing(false);
      }
      fileReader.onload = async (e) => {
        try {

          const fileContents = e.target.result;

          console.log("file read, creating excel objects");
          const workbook = new ExcelJS.Workbook();
          await workbook.xlsx.load(fileContents)

          const menuSheet = workbook.getWorksheet('Menu');
          const restInfoSheet = workbook.getWorksheet('Restaurant Info');

          console.log("excel objects created, generating JSON")
          try {
            const jOut = await toJSON({
              menuSheet,
              restInfoSheet
            })
            setJSON(jOut);
            setError(false);
          } catch (e) {
            console.error(e);
            setError(e.message);
          }
        } catch (e) {
          console.error(e);
        } finally {
          setIsProcessing(false)
        }
      };
      fileReader.readAsArrayBuffer(data.file[0]);
      setIsProcessing(true);
    }
  };

  return (
    <div className="w-full flex flex-col grow bg-base-200 h-screen overflow-scroll">
      <div className='mx-auto text-4xl text-slate-600 my-5 font-medium'> Menu Validator</div>
      <div className='flex flex-row overflow-scroll'>
        <div className='w-1/2 my-auto '>
          <div className='flex flex-row mx-5 grow p-5 shadow-xl rounded-lg mb-6'>
            <div className='w-1/2 flex flex-col'>
              <div className='mx-auto text-slate-600 font-medium'>Restaurant Columns</div>
              <ColTable data={RESTAURANT_COLUMN_NAMES} />
            </div>
            <div className='w-1/2 flex flex-col'>
              <div className='mx-auto text-slate-600 font-medium'>Menu Columns</div>
              <ColTable data={MENU_COLUMN_NAMES} />
            </div>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='mx-auto w-[35rem] rounded-xl bg-base-200 shadow-2xl p-10 flex flex-col'>
            <label htmlFor='fileinput'>Upload .xlsx (excel) file</label>
            <input
              type="file" id="fileinput"
              accept='.xlsx'
              className="file-input file-input-bordered file-input-accent w-full max-w-xs"
              required={true}
              {...register("file")}
            />
            {error && <span className='text-red-700 text-lg font-medium mt-5'>{error}</span>}
            <button
              type='submit'
              disabled={isProcessing}
              className='mt-5 w-48 btn bg-white border-solid border-primary border-[1px] hover:bg-gradient-to-r from-primary-2 to-primary-3 hover:underline'>
              {!isProcessing && <span>Verify</span>}
              {isProcessing && <span className="loading loading-ring loading-lg"></span>}
            </button>
          </form>
        </div>
        <div className='w-1/2 flex flex-row border-l-2 border-slate-300 border-solid mb-2 overflow-scroll'>
          {!json && <div className='p-10 bg-base-200 shadpw-xl text-3xl text-slate-600 my-auto mx-auto'>
            {"{ JSON Preview }"}
          </div>}
          {json && <div className="p-5 overflow-scroll w-full" >
            <ReactJson src={json} />
          </div>}
        </div>
      </div>
    </div>
  );
}

export default App;
