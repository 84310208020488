export default [
    {
        "cuisine": "Italian",
        "description": "Italian cuisine is known for its use of fresh ingredients, pasta dishes, and rich flavors. It includes dishes like pasta, pizza, risotto, and gelato."
    },
    {
        "cuisine": "Chinese",
        "description": "Chinese cuisine offers a variety of flavors and cooking styles, including stir-frying, steaming, and braising. It includes dishes like dim sum, sweet and sour chicken, and Peking duck."
    },
    {
        "cuisine": "Japanese",
        "description": "Japanese cuisine focuses on simplicity and presentation. It includes dishes like sushi, sashimi, tempura, and ramen."
    },
    {
        "cuisine": "Mexican",
        "description": "Mexican cuisine is known for its vibrant flavors and use of ingredients like beans, corn, and chili peppers. It includes dishes like tacos, enchiladas, and guacamole."
    },
    {
        "cuisine": "Indian",
        "description": "Indian cuisine offers a wide range of spices and flavors. It includes dishes like curry, biryani, tandoori, and samosas."
    },
    {
        "cuisine": "French",
        "description": "French cuisine is characterized by its emphasis on technique and high-quality ingredients. It includes dishes like coq au vin, croissants, and ratatouille."
    },
    {
        "cuisine": "Thai",
        "description": "Thai cuisine balances sweet, salty, spicy, and sour flavors. It includes dishes like green curry, pad Thai, and Tom Yum soup."
    },
    {
        "cuisine": "Greek",
        "description": "Greek cuisine features Mediterranean flavors and ingredients like olive oil, feta cheese, and olives. It includes dishes like moussaka, souvlaki, and baklava."
    },
    {
        "cuisine": "Spanish",
        "description": "Spanish cuisine varies by region, with an emphasis on seafood and bold flavors. It includes dishes like paella, tapas, and churros."
    },
    {
        "cuisine": "Korean",
        "description": "Korean cuisine is known for its use of fermented ingredients, spices, and bold flavors. It includes dishes like kimchi, bulgogi, and bibimbap."
    },
    {
        "cuisine": "Turkish",
        "description": "Turkish cuisine is known for its use of spices and diverse flavors. It includes dishes like kebabs, baklava, and Turkish delight."
    },
    {
        "cuisine": "Lebanese",
        "description": "Lebanese cuisine features a variety of fresh ingredients and herbs. It includes dishes like falafel, hummus, and tabbouleh."
    },
    {
        "cuisine": "Vietnamese",
        "description": "Vietnamese cuisine balances sweet, sour, and spicy flavors. It includes dishes like pho, spring rolls, and banh mi."
    },
    {
        "cuisine": "Moroccan",
        "description": "Moroccan cuisine is known for its use of spices and rich flavors. It includes dishes like tagine, couscous, and pastilla."
    },
    {
        "cuisine": "Brazilian",
        "description": "Brazilian cuisine features a mix of indigenous, African, and European influences. It includes dishes like feijoada, coxinha, and pão de queijo."
    },
    {
        "cuisine": "Russian",
        "description": "Russian cuisine reflects the country's vast geography. It includes dishes like borscht, blini, and pelmeni."
    },
    {
        "cuisine": "Caribbean",
        "description": "Caribbean cuisine is influenced by African, European, and indigenous traditions. It includes dishes like jerk chicken, rice and peas, and plantains."
    },
    {
        "cuisine": "Peruvian",
        "description": "Peruvian cuisine is known for its diverse ingredients and flavors. It includes dishes like ceviche, causa, and lomo saltado."
    },
    {
        "cuisine": "Ethiopian",
        "description": "Ethiopian cuisine features a mix of spices and stews served with injera (flatbread). It includes dishes like doro wat, injera, and kitfo."
    },
    {
        "cuisine": "German",
        "description": "German cuisine includes hearty and flavorful dishes. It includes dishes like bratwurst, sauerkraut, and schnitzel."
    },
    {
        "cuisine": "British",
        "description": "British cuisine features comfort foods and traditional dishes. It includes dishes like fish and chips, roast beef with Yorkshire pudding, and tea with scones."
    },
    {
        "cuisine": "Italian-American",
        "description": "Italian-American cuisine is influenced by Italian flavors with a twist. It includes dishes like spaghetti and meatballs, chicken Parmesan, and calzones."
    },
    {
        "cuisine": "Cajun",
        "description": "Cajun cuisine originates from Louisiana and features bold and spicy flavors. It includes dishes like gumbo, jambalaya, and crawfish étouffée."
    },
    {
        "cuisine": "Middle Eastern",
        "description": "Middle Eastern cuisine features a variety of grains, vegetables, and spices. It includes dishes like falafel, shawarma, and baklava."
    },
    {
        "cuisine": "Malaysian",
        "description": "Malaysian cuisine is known for its fusion of flavors from various cultures. It includes dishes like nasi lemak, satay, and laksa."
    },
    {
        "cuisine": "Indonesian",
        "description": "Indonesian cuisine features a mix of spices and ingredients. It includes dishes like nasi goreng, rendang, and satay."
    },
    {
        "cuisine": "Filipino",
        "description": "Filipino cuisine is a blend of indigenous, Spanish, Chinese, and American influences. It includes dishes like adobo, sinigang, and lechon."
    },
    {
        "cuisine": "Pakistani",
        "description": "Pakistani cuisine features a mix of spices and flavors. It includes dishes like biryani, nihari, and chapli kebab."
    },
    {
        "cuisine": "Egyptian",
        "description": "Egyptian cuisine includes a variety of grains, vegetables, and legumes. It includes dishes like koshari, ful medames, and mahshi."
    },
    {
        "cuisine": "Polish",
        "description": "Polish cuisine features hearty and traditional dishes. It includes dishes like pierogi, kielbasa, and bigos."
    },
    {
        "cuisine": "American",
        "description": "American cuisine is diverse and influenced by various cultures. It includes dishes like burgers, hot dogs, apple pie, and regional specialties."
    }
]
